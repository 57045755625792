// embed into bundle rather than retrieve from url
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

:root {
  --bgClr: #222;
  --clrRetake: #4a90e2;
  --clrDel: #f64b4b;
  --clrLink: #38f;

  --itemH: 100px;
  --iconWH: 32px;
  --footerH: 32px;
  --tb-height: 52px;
  --minPhotoH: calc(100% / 6);
}

button,
input {
  outline: none !important;
  border: none;
  border-radius: 0;
  background-color: white;
}

html {
  height: 100%;
  box-sizing: border-box;
  user-select: none;
}

body {
  background-color: var(--bgClr);
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Oswald', sans-serif;
  overflow-x: hidden;
  user-select: none;
}

////  ////////////
////////  ////////
////////////  ////
